import React, { Fragment } from "react"
import styled from "styled-components"

const Decoration = styled.span`
  position: relative;
  display: inline-block;
  transform: scale(1.1) rotate(-5deg) skew(-5deg);
  z-index: 0;
  background-color: #b3f0e1;
`
const DecorationSpan = styled.span`
  display: inherit;
  transform: scale(0.9) rotate(5deg) skew(5deg);
`
const Span = styled.span`
  z-index: 10;
  position: relative;
`

export const homeFeedData = [
  {
    title: (
      <Fragment>
        <Span>
          Personaliza todo<br />
        </Span>
        <Decoration>
          <DecorationSpan>a tu gusto</DecorationSpan>
        </Decoration>
      </Fragment>
    ),
    description:
      "Explora el gran catálogo de productos y complementos y crea la combinación que más se adapte a tus gustos. ¿Te atreves?",
    screenshot: require("../images/photoshop/toppings-centered.png"),
  },
  {
    title: (
      <Fragment>
        <Span>
        Realiza tu pedido <br />
        </Span>
        <Decoration>
          <DecorationSpan>sin esperas</DecorationSpan>
        </Decoration>
      </Fragment>
    ),
    description:
      "Haz tu pedido de manera sencilla y paga con tu tarjeta de forma segura. Sin importe mínimo ni comisiones. ¡Y sin colas!",
    screenshot: require("../images/photoshop/payment-centered.png"),
  },
  {
    title: (
      <Fragment>
        <Span>
        Sigue tu pedido <br />
        </Span>
        <Decoration>
          <DecorationSpan>en tiempo real</DecorationSpan>
        </Decoration>
      </Fragment>
    ),
    description:
      "Mediante el número de pedido en pantalla se te irá informando del estado del mismo hasta indicarte que está listo para recoger. ¡Qué aproveche!",
    screenshot: require("../images/photoshop/order-centered.png"),
  },
  {
    title: (
      <Fragment>
        <Span>
        Acumula puntos y<br />
        </Span>
        <Decoration>
          <DecorationSpan>gana recompensas</DecorationSpan>
        </Decoration>
      </Fragment>
    ),
    description:
      "Consigue fantásticas recompensas con los puntos que acumules en cada pedido. Cuantos más puntos consigas, mejores recompensas obtendrás.",
    screenshot: require("../images/photoshop/rewards-centered.png"),
  },
]
