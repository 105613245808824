import React from "react"
import styled from "styled-components"
import MainButton from "../MainButton"

const Hero = () => (
  <Container>
    <HeroGroup>
      <Title>
        <MainTitle>
          Personaliza y pide <br />
          sin esperas
        </MainTitle>
        <Description>
          Choosify es una APP que te permite personalizar a tu gusto los
          productos ofertados en los locales de venta adheridos. Sin esperas.
          Sin complicaciones. ¡Qué rico!
        </Description>
      </Title>
      <ButtonArea>
        <a
          href="https://apps.apple.com/es/app/choosify/id1467347746"
          target="_blank"
          rel="nofollow"
        >
          <AppStore />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=es.choosify"
          target="_blank"
          rel="nofollow"
        >
          <GooglePlay />
        </a>
      </ButtonArea>
      <br/>
      <a
        href="https://www.choosify.es"
        class="open-intercom"
        target="_blank"
        rel="nofollow"
      >
        <MainButton title={"Ver APP en versión WEB"} />
      </a>
    </HeroGroup>
    <Image />
  </Container>
)

export default Hero

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1188px;
  height: 100vh;
  margin: auto;
  justify-content: space-between;

  @media (max-width: 776px) {
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 0px;
    height: 100%;
    overflow: hidden;
  }
`
const HeroGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-top: auto;
  margin-bottom: auto;
  top: -6%;
  left: 6%;
  justify-content: center;
  position: relative;
  z-index: 2;
  @media (max-width: 776px) {
    width: 100%;
    align-items: center;
    padding: 0px;
    padding-bottom: 20px;
    left: 0%;
    z-index: 0;
  }
`
const Title = styled.div`
  max-width: 580px;
  margin-bottom: 42px;

  @media (max-width: 776px) {
    margin-bottom: 0px;
    text-align: center;
    padding: 30px;
    padding-bottom: 0px;
    padding-top: 0px;
  }
`
const MainTitle = styled.h1`
  font-size: 55px;

  @media (max-width: 776px) {
    font-size: 36px;
  }
`
const Description = styled.p`
  font-size: 24px;
  @media (max-width: 776px) {
    font-size: 18px;
  }
`

const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
`
const AppStore = styled.div`
  background: url(${require("../../images/hero/apple.png")});
  background-size: contain;
  width: 165px;
  height: 49px;
  margin-right: 10px;
`
const GooglePlay = styled.div`
  background: url(${require("../../images/hero/android.png")});
  background-size: contain;
  width: 165px;
  height: 49px;
`

const Image = styled.div`
  width: 476px;
  height: 536px;
  margin-top: auto;
  top: -6%;
  margin-bottom: auto;
  background: url(${require("../../images/helado-iphone.png")});
  background-size: cover;
  position: relative;
  @media (max-width: 776px) {
    max-width: 350px;
    max-height: 394px;
    margin: auto;
    margin-bottom: 30px;
  }
`

