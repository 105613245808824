import React from "react"
import styled from "styled-components"

const Sticky = ({ active }) => {
  let video0 = React.createRef()
  let video1 = React.createRef()
  let video2 = React.createRef()
  let video3 = React.createRef()

  let zIndex0 = -1
  let zIndex1 = -2
  let zIndex2 = -2
  let zIndex3 = -2

  const setAtributtes = async () => {
    if (active === 0) {
      zIndex0 = -1
      await video0
      if (video0.current != null) {
        video0.current.play()
      }
    } else {
      zIndex0 = -2
    }
    if (active === 1) {
      zIndex1 = -1
      await video1
      if (video1.current != null) {
        video1.current.play()
      }
    } else {
      zIndex1 = -2
    }
    if (active === 2) {
      zIndex2 = -1
      await video2
      if (video2.current != null) {
        video2.current.play()
      }
    } else {
      zIndex2 = -2
    }
    if (active === 3) {
      zIndex3 = -1
      await video3
      if (video3.current != null) {
        video3.current.play()
      }
    } else {
      zIndex3 = -2
    }
  }

  setAtributtes()

  return (
    <Container>
      <Wrapper>
        <Phone>
          <Area>
            <Image />
            <video
              style={{
                position: "absolute",
                top: 28,
                left: 104,
                width: 258,
                height: 549,
                zIndex: zIndex0,
              }}
              muted
              loop
              src={require("../../images/videos/product.mp4")}
              ref={video0}
            >
              <source type="video/mp4" />
            </video>
            <video
              style={{
                position: "absolute",
                top: 28,
                left: 104,
                width: 258,
                height: 549,
                zIndex: zIndex1,
              }}
              muted
              loop
              src={require("../../images/videos/payment2.mp4")}
              ref={video1}
            >
              <source type="video/mp4" />
            </video>
            <video
              style={{
                position: "absolute",
                top: 28,
                left: 104,
                width: 258,
                height: 549,
                zIndex: zIndex2,
              }}
              muted
              loop
              src={require("../../images/videos/order.mp4")}
              ref={video2}
            >
              <source type="video/mp4" />
            </video>
            <video
              style={{
                position: "absolute",
                top: 28,
                left: 104,
                width: 258,
                height: 549,
                zIndex: zIndex3,
              }}
              muted
              loop
              src={require("../../images/videos/rewards.mp4")}
              ref={video3}
            >
              <source type="video/mp4" />
            </video>
          </Area>
        </Phone>
      </Wrapper>
    </Container>
  )
}

export default Sticky

const Container = styled.div`
  position: sticky;
  top: 1px;
  width: 100vw;
  height: 1px;
  min-height: 0;
  padding-right: 0;
  z-index: 10;
  @media (max-width: 776px) {
    display: none;
  }
`
const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  margin: auto;
  overflow: hidden;
`
const Phone = styled.div`
  position: relative;
  align-self: center;
  width: 70%;
  max-width: 1188px;
  margin: auto;
  margin-left: 60%;
  margin-right: 6%;
`
const Image = styled.div`
  width: 400px;
  height: 650px;
  background: url(${require("../../images/iphone.png")});
  background-size: cover;
  z-index: 30;
`

const Area = styled.div`
  position: relative;
  width: 400px;
  height: 645px;
`
