import React, { useState } from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Hero from "../components/Index/Hero"
import Section from "../components/Index/Section"
import Sticky from "../components/Index/Sticky"
import styled from "styled-components"
import { homeFeedData } from "../providers/staticIndexContent"
import TrackVisibilityCustom from "../components/TrackVisibilityCustom"

const IndexPage = () => {
  const [active, setActive] = useState(0)
  return (
    <Layout>
      <SEO
        title="Choosify"
        description="Pide de manera fácil y personalizada entre todos los productos ofertados en la APP"
        path="https://info.choosify.es"
      />
      <Hero />
      <Youtube>
        <iframe style={{ borderRadius: "50px", position: "absolute", top: 0, bottom: 0}} width="80%" height="80%" src="https://www.youtube.com/embed/GRNaN_KwB_8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </Youtube>
      <Wrapper>
        <Sticky active={active} />
        {homeFeedData.map((elem, index) => {
          if (index != homeFeedData.length - 1) {
            return (
              <TrackVisibilityCustom
                key={index}
                onVisible={activeIndex => setActive(activeIndex)}
                index={index}
              >
                <Section
                  title={elem.title}
                  description={elem.description}
                  screenshot={elem.screenshot}
                />
              </TrackVisibilityCustom>
            )
          }
        })}
      </Wrapper>
      <TrackVisibilityCustom
        onVisible={activeIndex => setActive(activeIndex)}
        index={3}
      >
        <Section
          title={homeFeedData[homeFeedData.length - 1].title}
          description={homeFeedData[homeFeedData.length - 1].description}
          icon={homeFeedData[homeFeedData.length - 1].icon}
          screenshot={homeFeedData[homeFeedData.length - 1].screenshot}
        />
      </TrackVisibilityCustom>
      <Youtube>
        <iframe style={{ borderRadius: "50px", position: "absolute", top: 0, bottom: 0}} width="80%" height="80%" src="https://www.youtube.com/embed/y57jTU1uC0w" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </Youtube>
    </Layout>
  )
}

export default IndexPage

const Wrapper = styled.div`
  position: relative;
`

const Youtube = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 56.25%;
`


