import React from "react"
import styled from "styled-components"

const Section = ({ title, description, screenshot }) => (
  <Container>
    <Message>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Message>
    <Phone screenshot={screenshot} />
  </Container>
)

export default Section

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
  max-width: 1188px;
  margin: auto;

  @media (max-width: 776px) {
    flex-direction: column;
    justify-content: center;
    align-content: center;
    min-height: 100%;
  }
`

const Message = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  left: 6%;
  position: relative;
  max-width: 500px;
  margin-top: auto;
  margin-bottom: auto;

  @media (max-width: 776px) {
    width: 100%;
    max-width: 100%;
    left: 0%;
  }
`
const Title = styled.h2`
  font-size: 40px;
  margin-top: 10px;
  @media (max-width: 776px) {
    font-size: 30px;
    text-align: center;
    padding: 30px;
    padding-bottom: 0px;
    padding-top: 0px;
  }
`
const Description = styled.p`
  @media (max-width: 776px) {
    text-align: center;
    padding: 30px;
    padding-bottom: 0px;
    padding-top: 0px;
  }
`

const Phone = styled.div`
  display: none;
  width: 50%;
  height: 50%;
  background: url(${props => props.screenshot});
  background-size: cover;

  @media (max-width: 776px) {
    height: 470px;
    width: 290px;
    margin: auto;
    display: flex;
  }
`
