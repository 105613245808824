import React, { Component } from "react"
require("intersection-observer")

export default class TrackVisibilityCustom extends Component {
  ref = React.createRef()

  async componentDidMount() {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting === true) {
          this.props.onVisible(this.props.index)
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.8,
      }
    )

    if (this.ref.current) {
      observer.observe(this.ref.current)
    }
  }

  render() {
    return <div ref={this.ref}>{this.props.children}</div>
  }
}
